/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

html,
body {
    width: 100%;
    height: 100%;
    background: url("https://images.pexels.com/photos/1096658/pexels-photo-1096658.jpeg?auto=compress&cs=tinysrgb&h=650&w=940") center/cover no-repeat;
    font: bold 1rem Consolas;
} */

:root {
    --highlight: #ff9f1a;
    --font: #fff;
    --numbers: #ff9f1a;
    --underline: #fff;
}

.open,
.close {
    display: inline-block;
    padding: 0px 0 0 0px;
    cursor: pointer;
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
    transition: 0.5s;
    /* top: 0;
    left: 0; */
    position: fixed;
    top: 0%;
    left: 0%;
    text-align: center;
    text-transform: uppercase;
    background-color: black;
    z-index: 12;
    width: 100%;
}

.close {
    position: unset;
}

.navSubcategory {
    margin-left: 5rem;
    text-align: left;
}

.navNumbers {
    font-size: 2rem;
    color: var(--numbers);
    margin-right: 10px;

}

.overlay {
    position: fixed;
    width: 0;
    top: 0;
    background-color: #333;
    opacity: 0.7;
    overflow-x: hidden;
    height: 100%;
    transition: 0.5s;
    z-index: 20;
    /* CHANGED */


}

.overlay-content {
    text-align: left;
    margin-left: 25rem;

    position: relative;
    width: 100%;
    padding-top: 50px;
    margin-top: 5%;
    text-transform: uppercase;

}

.overlay-content a {
    font-size: 4rem;
    color: var(--font);
    display: block;
    padding: 10px;
    margin-bottom: 4px;
    text-decoration: none;
    transition: 0.3s;
    position: relative;
}

.overlay-content a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: var(--underline);
    transition: 0.3s;
    display: block;
}

.overlay-content a:hover {
    color: var(--highlight);
}

.overlay-content a:hover:after {
    width: 25%;
}


/* iPad and below */
@media only screen and (max-width: 900px) {

    .overlay-content {
        text-align: center;
        margin-left: 0rem;
        padding-top: 0px;
        margin-top: 2%;
    }


    .overlay-content a {
        font-size: 1.2rem;
    }

}