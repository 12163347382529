/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */

/* * {
    box-sizing: border-box;
}

.scrollAnimation {
    background-color: #efedd6;
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow-x: hidden;
} */

/* h1 {
    margin: 10px;
} */

.box {
    /* background-color: steelblue;
    color: #fff; */

    /* display: flex;
    align-items: center;
    justify-content: center; */

    /* width: 400px;
    height: 200px; */
    /* margin: 10px; */
    border-radius: 10px;
    /* box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3); */
    transform: translateX(400%);
    transition: transform 0.4s ease;
}

.box:nth-of-type(even) {
    transform: translateX(-400%);
}

.box.show {
    transform: translateX(0);
}

/* .box h2 {
    font-size: 45px;
} */