body,
#slider,
.wrap,
.slide-content {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 50vh;
    overflow-x: hidden;
}

.wrap {
    position: relative;
}

.slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kitchenSlide1 {
    background-image: url("../public/img/kitchen/kitchen7.jpg");
}

.kitchenSlide2 {
    background-image: url("../public/img/kitchen/kitchen2.jpg");
}

.kitchenSlide3 {
    background-image: url("../public/img/kitchen/kitchen3.jpg");
}


.bathSlide1 {
    background-image: url("../public/img/bath/bath18.jpg");
}

.bathSlide2 {
    background-image: url("../public/img/bath/bath9.jpg");
}

.bathSlide3 {
    background-image: url("../public/img/bath/bath2.jpg");
}

.basementSlide1 {
    background-image: url("../public/img/basement/basement1.jpg");
}

.basementSlide2 {
    background-image: url("../public/img/basement/basement2.jpg");
}

.basementSlide3 {
    background-image: url("../public/img/basement/basement3.jpg");
}

.poolSlide1 {
    background-image: url("../public/img/pools/pool2.jpg");
}

.poolSlide2 {
    background-image: url("../public/img/pools/pool3.jpg");
}

.poolSlide3 {
    background-image: url("../public/img/pools/pool4.jpg");
}

.patioSlide1 {
    background-image: url("../public/img/patio/patio1.jpg");
}

.patioSlide2 {
    background-image: url("../public/img/patio/patio2.jpg");
}

/* .patioSlide3 {
    background-image: url("../public/img/patio/patio4.jpg");
} */

.slide-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.slide-content span {
    font-size: 5rem;
    color: #fff;
}

.arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    width: 0;
    height: 0;
    border-style: solid;
}

.arrowLeft {
    border-width: 30px 40px 30px 0;
    border-color: transparent #fff transparent transparent;
    left: 0;
    margin-left: 30px;
}

.arrowRight {
    border-width: 30px 0 30px 40px;
    border-color: transparent transparent transparent #fff;
    right: 0;
    margin-right: 30px;
}