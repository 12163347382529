* {
  font-family: 'Roboto', sans-serif;

}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format('truetype');

  font-family: "Impact";
  src: url("./fonts/Impact/Impacted.ttf") format('truetype');
}





html,
body {
  height: 100%;
  background-color: white;
}

.servicesPageBody {
  background-color: black;
}

h3 {
  color: white;
}

p {
  color: black;
  font-size: 1.5rem;
  margin-left: 30px;
}

ul {
  display: inline-block;
  text-align: left;
}

li {
  text-decoration: none;
  list-style-type: none;
  font-size: 1.5rem;
}

/* iOS prevent zooming */
body {
  touch-action: pan-x pan-y;
}

.counterContainer {
  margin-top: 400px;
}


.handsImage {
  width: 16rem;
  height: 15rem;

  /* border: 50px solid blue; */
  /* z-index: 110; */
  margin-left: 15vw;
  position: relative;
}

.diamondImage {
  width: 16rem;
  height: 15rem;
  margin-left: 15vw;
  position: relative;

}

.counterCardContainerFirst {
  /* position: relative; */
  margin-left: 10vw;
}

.counterCardContainerSecond {
  /* position: relative; */
  margin-left: 12vw;
}

.counter {
  color: black;
  font-size: 60px;

  /* top: 60px;
  left: 160px; */
  font-weight: bold;
  /* position: absolute; */
  position: relative;
}

.counterTitle {
  /* top: 50px; */
  color: black;
  /* position: absolute; */
  /* top: 120px;
  left: 160px; */
  font-size: 40px;
  font-weight: bold;
  margin-top: 100px;
  position: relative;

}



.counterCardImage {
  width: 30rem;
  position: relative;
  text-align: center;
  /* height: 14rem; */
  position: relative;
}


.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: black;
  transform-origin: 0%;
  z-index: 10;
}


.scrollStartText {
  z-index: 14;
  color: white;
  text-align: left;
  font-size: 24px;
  position: fixed;
  bottom: 0;
}

/* .scrollFinishText {
  z-index: 14;
  color: white;
  text-align: right;
  font-size: 24px;
  position: fixed;
  right: 0;
  bottom: 0;
} */

.scrollFinishText {
  z-index: 14;
  color: white;
  text-align: right;
  font-size: 24px;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
  bottom: 0;
}


figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  position: relative;
}

figure figcaption {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /* flex-direction: column;
  text-align: center; */
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */

}

figcaption {
  margin-left: -100px;
  width: 300px;
  height: 100px;
  display: none;
  border: 50px solid green;
}

figcaption:hover {
  display: block;
}

.callUsBtn {
  color: #fff;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  display: none;
}


#overTopImageGradient {

  /* background-image:
    url('/img/bath/bath1.jpg'); */



}

#overTopImageGradient:hover {

  background-image:
    repeating-radial-gradient(circle, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.75));
  width: 50%;
  height: 400px;
  background-size: cover;
  color: white;
  padding: 20px;
}

.pointer {
  cursor: pointer;
}


.rightToLeft-lineBreaks {
  display: none;
}

.target {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: black;
}

/* #target-id {
  background-image:
    none;
  z-index: 2;
} */

.nav-link {
  z-index: 1;
  font-size: 1.8rem;
  font-weight: 900;
  letter-spacing: 4px;
  margin-top: -9px;
  margin-right: 20px;

}


#nav-link-quote {
  margin-left: -150px;
}

.companyName {
  z-index: 3;
  width: 450px;
  height: 50px;
  /* border: 100px solid red; */
  /* margin-left: auto;
  margin-right: auto; */

  display: block;
  position: relative;

  /* border: 10px solid green; */
  font-family: 'Impact', 'Open Sans', 'Roboto', sans-serif;
  text-transform: uppercase;
  color: black;

  /* left: -42%;
  margin-top: 20px;
  margin-left: 160px; */

  /* border: 2px solid red; */
  font-size: 4.2rem;
  letter-spacing: 2px;
  position: absolute;
  top: 500px;
  left: 50%;

  /* top: 40%; */


}

/* .nav-color-dark {
  color: black;
}

.nav-color-light {
  color: white;
} */

.navbar {
  justify-content: center;
  text-transform: uppercase;
}

.services-dropdown {
  text-transform: uppercase;
  font-size: 2rem !important;
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /* background-image:
    repeating-radial-gradient(circle, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.55)); */
  /* background-image: url("../public/img/kitchen/kitchen8.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: relative; */



  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; */
}

/* 
button {
  background-color: #666;
  border: medium none;
  color: #fff;
  display: block;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
}

button.active {

  background-color: #0077a2;
} */


.logo {
  width: 100px;
  height: 100px;
  /* margin-left: -120%;
  margin-right: 2.5rem; */
  z-index: 1;
  position: absolute;
  /* margin-top: auto;
  margin-bottom: auto; */
  /* display: block;
  text-align: center; */
  top: 500px;
  left: 40%;
}

#quoteLogo {
  text-align: center;
  top: 28%;
}

.sslImage {
  width: 200px;
  height: 100px;
  z-index: 1;
  position: absolute;
  bottom: 13%;
  left: 32%;
}

/* .bgImage {
  background-image:
    repeating-radial-gradient(circle, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.75));
  background-position: center;
  background-repeat: no-repeat;

  min-width: 100vw;
  min-height: 100vh;
  margin: auto;
  z-index: 1;
  border: 20px solid red;
}

.bgImage:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
} */

.footer-social-icon {
  width: 60px;
  height: 60px;


}


/* ALL SERVICES PAGE */


/* .servicesImageContainer {
  position: relative;
  text-align: center;
  color: white;

} */

/* .servicesImageContainerBefore {
} */

.servicesHeadingBefore {
  color: black;
  margin-left: 20rem;
  font-size: 40px;
  /* border: 10px solid red; */
  width: 200px;
  font-weight: bold;
  /* color: white; */
}

.servicesHeadingAfter {

  margin-left: 20rem;
  /* border: 10px solid red; */
  color: black;



}

.services-container {
  display: grid;
  grid-template-columns: 50% 50%;
  min-width: 100vw;

}

.services-item {}

.buttonServices {
  height: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
}

.services-flex-container {
  display: flex;

  flex-wrap: wrap;

  font-size: 30px;
  margin-left: 10%;
  /* width: 0px;
  height: 900px; */
  /* text-align: center; */
  /* box-sizing: border-box; */
}

.services-flex-item-left {
  /* background-color: #f1f1f1; */
  padding: 10px;
  /* flex: 0%; */
  margin-right: 50px;

  /* background-color: green; */

  /* padding-left: -200px; */
  /* margin-left: -200px; */
  /* box-sizing: border-box; */
}

.services-flex-item-right {
  /* background-color: dodgerblue; */
  padding: 10px;
  /* margin-left: -200px; */
  flex: 50%;
  /* box-sizing: border-box; */
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
/* @media (max-width: 800px) {
  .flex-item-right, .flex-item-left {
    flex: 100%;
  }
} */

.servicesPara {
  margin-left: 15%;
  margin-right: 15%;
}

.informative-icons {
  width: 100px;
  height: 100px;
  text-align: center;

  position: relative;
}

.informative-icons-left {
  margin-left: 35%;
}

.informative-icons-right {
  margin-left: 35%;
}

.beforeAfterImg {
  width: 400px;
  height: 400px;
  /* border: 20px solid red; */
  /* margin-left: 25px;
  margin-top: 25px; */

  /* margin-left: 40%; */
}

/* margin-left: auto;
  margin-right: auto;
  display: block; */


/* .beforeAfterText {
  position: absolute;
  top: 50%;
  left: 70%;
  font-weight: bold;
} */

/* ALL SERVICES PAGE */

/* POOLS */
.pool {
  /* margin-right: 50px; */
  /* margin-top: 50px; */
  margin-top: 30px;
  margin-left: -40px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* border: 50px solid green; */
}

/* .poolLineBreaks {
  display: none;
} */

.poolPara {
  padding-left: 100px;
  margin-right: 100px;
  margin-top: 50px;

}

/* POOLS */

/* BATH */
.bath-grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: 1200px; */
  padding: 10px;
  /* border: 20px solid red; */
}

.bath-grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-right: 10px;
}

/* Image overlay */

.imageContainer {
  position: relative;
  /* width: 50%; */
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 250px;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

}

/* hover for desktop and active for mobile */

.imageContainer:hover .image,
.imageContainer:active .image {
  opacity: 0.3;
}

.imageContainer:hover .middle,
.imageContainer:active .middle {
  opacity: 1;
}

.buttonText {
  /* background-color: #04AA6D; */
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: none;
  /* background-color: rgb(21, 107, 204); */
  border: 3px solid #ffffff;
  /* color: unset !important; */
}

.betweenButtons {
  margin-top: 2rem;
}

/* .buttonText:link,
.buttonText:active,
.buttonText:visited,
.buttonText:hover,
.buttonText:active,
.buttonText:focus {
  text-decoration: none !important;
  color: green !important;
} */

.buttonText:hover {
  background-color: black;
  transition: 1s ease;
  border-radius: 10px;
  border: 3px solid #434243;
  color: white;
}

.buttonZoomInText {
  color: white;
  font-size: 16px;
  /* padding: 16px 32px; */
  margin-top: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: none;
  /* pointer-events: none; */
}

/* Image overlay */


.bath-image {
  /* margin-right: 50px;
  margin-top: 50px;
  margin-left: 50px; */
  height: 1000px;
  width: 700px;
  position: relative;
  border: 180px solid rgb(0, 255, 81);
  margin-right: 140px;
  width: auto;
  max-width: 100%;
  height: auto;
}

/* BATH */

.alignedPara {
  text-align: center;
  word-wrap: normal;
  /* word-break: break-all; */
  text-align: left;
  margin-left: 20px;
  text-decoration: none;
}

/* Kitchen */
.grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: 1200px; */
  padding: 10px;
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-right: 10px;
  margin: 20px;
}





.kitchen-container {
  /* top: 200px;
  margin-top: 4500px; */
}

.kitchenHeading {
  text-align: center;
  color: white;
}

.kitchen {
  /* margin-right: 50px;
  margin-top: 50px;
  margin-left: 50px; */
  height: 1000px;
  width: 700px;
  width: auto;
  /* min-width: 100%; */
  max-width: 100%;
  height: auto;

}


.kitchen-card-bg {
  height: 2400px;
  width: 700px;
}

.kitchen-para {
  /* margin-right: 500px; */
  /* margin-top: 500px; */
  float: left;
}

.kitchen-image-right {
  /* float: left; */
  border: 20px solid yellow;

}

.image-border {
  /* width: 400px;
  height: 200px; */
  border: 2px solid #fff;
  /* background: url(img/tiger.png) no-repeat; */
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}



/* Kitchen */

/* BASEMENT page */

/* .basementImage {
  width: 400px;
  height: 400px;
  margin-left: 25px;
  margin-top: 25px;


} */
/* margin-right: 25px; */
/* margin-right: 25px; */
/* display: inline-block; */
/* position: relative; */

/* left: 0;
  right: 0; */
/* text-align: center; */

.basementHeading {
  text-align: center;
  color: white;
  font-size: 48px;
  text-transform: uppercase;
  background-color: black;
}

.basementPara {
  margin-left: 450px;
  margin-right: 450px;
  text-align: center;
}

/* Fencing, Deck and Patio */
.decks-grid-container {
  display: grid;
  grid-template-columns: 100%;
  /* grid-template-rows: 1200px; */
  padding: 10px;
  /* border: 20px solid red; */
}

.decks-grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-right: 10px;
}

.decks-image {
  width: auto;
  max-height: 40rem;
  height: auto;
  margin-top: 30px;
  margin-left: -40px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;

}

.deckPara {
  margin-left: 50px;
  margin-right: 25px;
}


/* GALLERY PAGE */
.before-after {
  font-size: 1.8rem;
  font-weight: bold;
}

.headingHover {
  text-align: center;


}

/* GALLERY PAGE */

/* FAQ */
.accordion {
  width: 60%;
  left: 20%;
  background-color: #2f2f30 !important;
  /* position: relative;
  margin-left: 5%;
  margin-right: auto;
  display: block; */

}

.accordHeading {
  font-size: 1.8rem !important;
  color: #0C7AFF;


}

.accordPara {
  font-size: 1.5rem !important;
  color: #FFBF19;
}

.faq {
  width: 200px;
  height: 200px;
  margin-left: 45%;
}

.page-mainHeading {
  font-size: 40px;
  min-width: 100vw;
  height: 200px;
  text-align: center;
  padding-top: 75px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  /* margin-left: 3.5vw; */
}

.serviceSectionCards {
  margin: 15px;
  border: 10px solid black;
  border-radius: 30px;
  /* background-image: linear-gradient(-135deg, rgb(0, 0, 0), rgb(255, 255, 255), rgb(14, 14, 14)); */
  box-shadow: 0px 17px 20px rgb(255, 255, 255);

}

.serviceSectionCardPool {
  height: 1030px;
}

.serviceSectionIntroText {
  opacity: 1;
}

.faq-heading {

  text-align: center;
  color: #0C7AFF;

}

#faq-heading-answers {
  color: #FFBF19;

}

.faqLineBreaks {
  display: block;
}

/* ABOUT PAGE */
.canadianFlagIcon {
  width: 75px;
  height: 75px;
}

.aboutUsHeading {
  text-shadow: 1px 1px black;
  font-size: 3rem;
  text-transform: uppercase;
}

.localPara {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 2rem;
}

/* .about-grid-container {
  display: grid;
  grid-template-columns: 30% 70%;

  padding: 10px;
  grid-template-rows: min-content;

} */

.about-grid-item {
  background-color: #FF212B;
  background-image: unset;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-right: 10px;
  margin: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0);
  /* border: 2px solid white; */
  /* background-image: url("../public/img/canadian-bg.png"); */
  background-repeat: repeat;
  background-size: stretch;
  background-size: 100% 100%;
  border: 5px solid black;
  /* display: grid; */
  /* grid-template-columns: 60%; */
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.about-grid-item-top {
  background-color: #FF212B;
  background-image: unset;
  /* display: unset;
  align-items: unset;
  justify-content: unset; */
  /* display: grid;
  align-items: inherit;
  justify-content: inherit; */
}

.vanPic {
  width: 400px;
  height: 400px;

  position: relative;
  /* margin-top: 100px; */

  box-shadow: 5px 5px 10px rgba(0, 0, 0);
  margin-left: auto;
  margin-right: auto;
}

.canadianFlag {
  width: 200px;
  height: 200px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}


/* ABOUT PAGE */


/* CONTACT PAGE */

.spanSameLine {
  display: inline-block;

}

.socials {
  position: relative;
  left: 30%;
  margin: 0 auto;
  z-index: 3;
}

.home-socials {
  /* margin: 20px; */
  bottom: 0;
  text-align: left;
  width: 100%;
  /* background-image:
    repeating-radial-gradient(circle, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.75)); */
  /* border: 20px solid green; */
  /* object-fit: cover;
  z-index: 2; */
  position: fixed;

  left: 0;
  right: 0;
  margin: 0 0;
  text-align: center;



}

.home-social-icons-facebook {
  margin-left: 10px;
}

.home-social-icons-phone {
  margin-left: 10px;
}

.home-social-email {
  margin-left: 150px;
}

.home-social-instagram {
  margin-left: -240px;
}

.home-social-responsiveness {
  display: none;
}

.home-socials-large-screens {
  display: block;
}

.home-social-icons {
  z-index: 2;
  position: relative;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  margin-top: 5px;

}

.home-social-icons-email {
  left: 20px;
}

.home-socials-icons-large-screens {
  /* text-align: left;
  word-wrap: break-word;
  word-break: break-all;
  text-align-last: left;
  margin-left: 0px;
  text-decoration: none; */
  /* left: -150px;
  margin-left: -300px; */
  /* border: 5px solid green; */
  margin-left: 350px;

}


@keyframes socialsMenuPopOut {
  100% {
    height: 35%;
  }
}

@keyframes socialsPopOut {
  0% {
    transform: translateX(180%);
  }

  100% {
    transform: translateX(30%);
  }
}

.animationCleanUp {
  height: 35%;
}

.socialsBg {
  height: 5%;
  width: 10%;
  position: absolute;
  left: 90%;
  z-index: 20;
  top: 40%;
  background-color: #373333;
  overflow: hidden;

}


.socialsIconsGroup {}

.socialsBg:hover {
  animation: socialsMenuPopOut 1s linear 1;
  /* animation: socialsPopOut 5s linear 1; */
}

.socialsPopOut {
  animation: socialsPopOut 2s linear 1;

}


.socialsHamburgerIcon {
  /* width: 2rem;
  height: 2rem; */
  font-size: 2rem;
  color: white;
}

.socialsMenuText {
  color: white;
}

.socials-container {
  /* border: 10px solid red; */
  width: 50vw;
  margin-left: 40vw;
  margin-top: -7em;
  margin-bottom: 1em;
}

.social-icons {
  text-decoration: none;
  position: relative;
  margin: 20px;


}

.home-social-text {
  margin-left: 15px;
  color: black;
  font-size: 1.7rem;
  font-weight: 900;
  text-shadow: 1px 1px white;
  letter-spacing: 1px;

}

.home-social-text-email {
  margin-left: 30px;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  /* box-shadow: none !important;
  border: 0 !important; */
}

.social-text {
  margin-left: 15px;
  color: black;
  font-size: 2.5rem;
  font-weight: bolder;
  /* text-shadow: 2px 2px black; */
  text-decoration: none;
  letter-spacing: 1px;
}

.footer-service-area-text {
  text-shadow: 2px 2px white;
  color: black;

}


/* Quote page */
/* .quoteContainer {
  color: white;
  text-align: center;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;
} */
/* grid-template-rows: 700px; */



/* text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative; */
/* left: 50%;
  margin-right: -50%; */

.quoteHeading {
  text-align: center;
  color: white;
}

#quote {
  height: 170px;
}

.quote-container {
  display: grid;
  grid-template-columns: 100%;

}

/* .quote-grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-left: 0%;
} */

/* background-color: rgba(255, 255, 255, 0.8); */
/* margin-right: 10px;
  margin: 20px; */

.quoteImgSupport {
  width: 90%;
  height: 400px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: block;

}

.formInstagramPost {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.quoteForm {
  background-color: white;


  padding: 20px;
  font-size: 30px;
  text-align: center;
  width: 35vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vh;
  display: block;
  border: 10px solid black;
  border-radius: 30px;
  /* background-image: linear-gradient(-135deg, rgb(0, 0, 0), rgb(255, 255, 255), rgb(14, 14, 14)); */
  box-shadow: 0px 17px 20px rgb(255, 255, 255);

}


.formHeading {
  text-transform: uppercase;
  font-weight: bold;
}

.formLabels {
  color: black;
  font-size: 1.4rem;
  margin-top: 20px;

}

.formInputText {
  width: 300px;
  text-align: center;
  /* border: 2px solid black; */

  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid black;

}

input {
  padding: 5px;
  margin: 5px 0;
  outline: none;
}

input:focus,
input:active {
  border-color: transparent;
  border-bottom: 5px solid #1c87c9;
}

#formInputMessage {
  height: 250px;
  width: 355px;
}

.formSubmitBtn {
  background-color: white;
  color: black;
  padding: 20px;
  font-size: 1.4rem;
  font-weight: bolder;
}

/* LOCATION & HOURS PAGE */

.location-container {
  color: white;
  /* text-align: center; */
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;

}

.location-grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-left: 0%;

}

.simpleMap {
  height: 500px;
  width: 600px;
}

.serviceAreas {
  font-size: 3rem;
  color: black;
}

.headingHoursOfOperation {
  color: black;
  font-size: 3rem;
  /* margin-left: 150px; */
}

.hoursOfOperation {
  text-align: center;
}

.googleMap {
  height: 900px;
  width: 800px;
  margin-left: -350px;
}


/* CONTACT PAGE */

/* .contact-grid-container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 900px;
  padding: 10px;
} */

.contact-grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin-right: 10px;
  margin: 20px;
  border: 2px solid white;
  /*
  background-image: url("../public/img/aboutUsBg.jpeg");
  background-repeat: no-repeat;
  background-size: stretch;
  background-size: 100% 100%;
  border: 5px solid black;
  */
  /* display: grid; */
  /* grid-template-columns: 60%; */
  width: 100%;
  height: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.contactUsHeading {
  text-transform: uppercase;
  color: white;
  font-size: 1.7rem;
  font-weight: 900;
  text-shadow: 1px 1px black;
}

.contactUsSubHeading {
  text-transform: uppercase;
  color: black;
  font-size: 1.7rem;
  font-weight: 900;
  text-shadow: 1px 1px white;
}

.grid-container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}




#contactUsHeading {
  color: black;
}

.contactSupportImage {
  width: 270px;
  height: 270px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0);
  position: relative;
}

.buttonEmailUs {
  margin-bottom: 40px;
  width: 11rem;
}

.buttonEmailUsText {
  margin-left: 10px;
  font-weight: 900;
  font-size: 1.2rem;
}

.buttonCallUs {
  margin-bottom: 40px;
  width: 9rem;
}

.buttonCallUsText {
  margin-left: 10px;
  font-weight: 900;
  font-size: 1.2rem;
}

/* .contactCard {
  background-color: #484A4C;
  background-color: black;
  margin-left: 20%;
  margin-left: auto;
  margin-right: auto;

}

.contactCardTitle {
  color: white;
}

.contactCardLink {
  color: white;
  font-size: 1.5rem;
}

.contactUsHeading {} */

.contactPara {
  color: #57BDEA;
  color: white;
  font-size: 1.7rem;
  font-weight: 900;
  text-shadow: 1px 1px black;
  margin-right: 100px;
}

/* .contactCardText {
  margin-top: 20px !important;
  border: 10px solid yellow;
} */

/* FOOTER */
.footer {
  /* color: white; */
  text-transform: uppercase;
  /* background-color: #484A4C; */
  /* background-color: transparent; */
  /* position: fixed; */
  /* left: 0;
  bottom: 0; */
  /* width: 100%; */
  /* position: fixed; */

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */


}

/* 
body>footer {
  position: sticky;
  top: 100vh;
  bottom: 0;
} */

.footer-grid-container {
  /* display: grid;
  grid-template-columns: 25% 30% 45%;
  grid-template-rows: 400px; */
  padding: 10px;
  border: 10px solid red;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* padding: 20px; */
  /* font-size: 30px; */
  text-align: center;
  /* margin-right: 10px; */
  /* border: 1px dotted white; */
  background-color: #ffffff10;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 10px solid green;
  z-index: 20;
  height: 20vh;
  width: 20vw;
}

.footer-social-icon {
  margin-top: 20px;
  font-size: 30;
}

.footer-social-material-icon {
  width: 100px;
  height: 100px;
}

.footer-logo {
  width: 100px;
  height: 50px;
}

.footer-socials-heading {
  /* text-align: left;
  margin-left: 150px; */
}

.checkmark {
  width: 30px;
  height: 30px;
}

.daysOfOperation {
  margin-left: -40px;
}




.social-text {
  font-size: 1.4rem;



}

/* FOOTER */

.hide {
  display: none;
}


/* MEDIA QUERIES */



/* above iPad */
@media only screen and (min-width: 768px) and (max-width: 1450px) {

  .nav-link {
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 10px;
  }

  .logo {
    width: 100px;
    height: 100px;
  }

  .rightToLeft-lineBreaks {
    display: block;
  }

  /* Home page */
  .home-social-text {
    font-size: 0.7rem;
    font-weight: 900;
    text-shadow: 1px 1px white;
    letter-spacing: 1px;
  }

  .home-social-text-email {
    margin-left: 15px;
  }

  /* .home-socials {
    text-align: left;
  } */

  .home-social-icons {
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }

  .home-social-icons-facebook {
    margin-left: -10px;
  }

  .home-social-icons-phone {
    margin-left: -100px;
  }

  .home-social-icons-email {
    left: 0px;
  }

  .home-social-email {
    margin-left: -70px;
  }

  .home-social-instagram {
    margin-left: 0px;
  }

  .home-social-text {

    font-size: 1.2rem;
    letter-spacing: 0px;

  }

  .home-social-responsiveness {
    display: block;
  }

  .home-socials-large-screens {
    display: none;
  }

  .home-socials-icons-large-screens {
    margin: 0;
  }

  .social-icons {
    /* margin-left: 25%; */
  }




  .footer-socials-heading {
    margin-left: 35%;
  }

  /* Bath */
  .bath-grid-container {
    grid-template-columns: 50% 50%;
  }

  /* Kitchen */
  .grid-container {
    grid-template-columns: 50% 50%;
  }



  /* Basement */
  .basementPara {
    margin-left: 200px;
    margin-right: 200px;
  }

  /* Pools */
  /* .poolLineBreaks {
    display: block;
  } */



  /* Decks */
  /* Fencing, Deck and Patio */
  /* .deckPara {
    margin-left: 125px;
    margin-right: 50px;
    margin-bottom: 100px;
  } */

  /* Footer */
  .footer-grid-container {
    display: grid;
    grid-template-columns: 100%;
  }

  /* ABOUT PAGE */
  /* .about-grid-container {
    grid-template-columns: 100%;
    grid-template-rows: 900px;
    border: 20px solid green;
  } */

  .about-grid-item {}



  .vanPic {
    width: 400px;
    height: 450px;
    /* margin-top: 200px; */
  }

  /* ABOUT PAGE */

  .contact-grid-container {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1050px;
    margin-top: 50px;
  }

  .contact-grid-item {}


  .contactCard {
    margin-left: 30%;
    /* border: 20px solid red; */
  }



}



@media only screen and (min-width: 950px) and (max-width: 1040px) {
  .nav-link {
    font-size: 0.75rem;
    font-weight: bold;
    margin-right: 0px;
  }
}



/* iPad and below */
@media only screen and (max-width: 900px) {

  .nav-link {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 30px;
    margin-right: 0;
    /* border: 10px solid green; */
  }


  .logo {
    width: 100px;
    height: 100px;
    margin-left: 40px;
    left: -10%;
    top: 41%;

  }

  .companyName {
    /* left: -18%;
    width: 220px;
    margin-top: -70px;
    margin-left: 205px; */
    /* border: 2px solid green; */

    /* font-size: 1.8rem; */
    color: black;
    z-index: 10;
    position: relative;
    font-size: 1.8rem;
    left: 38%;
    /* top: 71%; */
    margin-left: unset;
    margin-top: -32%;

    /* border: 10px solid green; */
  }

  .counterContainer {
    margin-top: -800px;
  }

  .handsImage {
    width: 10rem;
    height: 9rem;
  }

  .diamondImage {
    width: 10rem;
    height: 9rem;
    margin-left: 15%;
    margin-top: 2rem;
  }

  .counterCardContainerFirst {
    /* position: relative; */
    margin-left: unset;
    text-align: center;
  }

  .counterCardContainerSecond {
    /* position: relative; */
    /* margin-left: 150px; */
    text-align: center;
    margin-left: unset;
  }

  .counter {
    font-size: 28px;
  }

  .counterTitle {
    font-size: 28px;
  }

  /* #navbar {
    z-index: 3;
    border: 10px solid yellow;
    left: 5%;
  } */

  #nav-link-quote {
    /* margin-left: unset; */
    margin-left: auto;
    margin-right: auto;
  }

  .services-container {

    grid-template-columns: 100%;
    min-width: 80vw;
    margin-left: -10%;

  }

  .serviceSectionCardPool {
    height: 340px;
  }

  .headingHoursOfOperation {
    margin-left: unset;
  }

  .hoursOfOperation {
    text-align: center;
  }

  /* #background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

  } */

  /* background-image:
      repeating-radial-gradient(circle, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.55)); */
  /* background-image: url("../public/img/kitchen/kitchen8.jpg"); */
  /* background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    position: relative; */


  /* Home page */


  .home-social-text {
    font-size: 0.7rem;
    font-weight: 900;
    text-shadow: 1px 1px white;
    letter-spacing: 1px;
  }

  .home-social-text-email {
    margin-left: 15px;
  }

  .home-socials {
    text-align: left;
  }

  .home-social-icons {
    width: 30px;
    height: 30px;
  }

  .home-social-icons-facebook {
    margin-left: unset;
  }

  .home-social-icons-phone {
    margin-left: unset;
  }

  .home-social-icons-email {
    left: 0px;
  }

  .home-social-email {
    margin-left: 0px;
  }

  .home-social-instagram {
    margin-left: 0px;
  }

  .home-social-text {

    font-size: 1.2rem;
    letter-spacing: 0px;

  }

  .home-social-responsiveness {
    display: block;
  }

  .home-socials-large-screens {
    display: none;
  }

  .home-socials-icons-large-screens {
    margin: 0;
  }

  /* SERVICES PAGE*/


  .servicesImageContainerBefore {
    margin-left: 0%;
  }

  .beforeAfterImg {
    width: 90%;
    margin-left: unset;
  }

  .beforeAfterText {
    left: 50%;
  }

  .kitchen {
    /* margin-right: 50px;
    margin-top: 50px;
    margin-left: 50px; */
    /* height: 700px;
    width: 500px;
    width: 100%; */
  }

  /* Pools */
  .pool {
    /* width: 25rem; */
    margin-top: 30px;
    margin-left: -40px;
    max-width: 90%;
  }

  /* .poolLineBreaks {
    display: block;
  } */

  .poolPara {
    padding-left: 50px;
    margin-right: 50px;
  }


  /* Bath */
  .bath-grid-container {
    grid-template-columns: 100%;
  }

  .bath-image {
    /* height: 800px; */
    width: 100%;
  }

  /* Kitchen */
  .grid-container {
    grid-template-columns: 100%;
  }

  /* Basement */
  .basementPara {
    margin-left: 50px;
    margin-right: 50px;
  }

  /* FAQ */
  .faq {
    margin-left: 20%;
  }

  .accordion {
    width: 90%;
    left: 5%;
  }

  .faqLineBreaks {
    display: none;
  }

  .page-mainHeading {
    height: 250px;
    padding-top: 50px;
    font-size: 30px;
  }


  /* Quote */
  /* .quoteContainer {

    grid-template-columns: 100%;
    
  } */

  .quoteImgSupport {
    width: 300px;
    height: 300px;
  }

  .formInstagramPost {
    /* width: 35px; */
    /* left: 30%; */
    /* left: 15%; */
    /* margin-left: 155%;
    left: 150%; */
    padding-left: 60px;
    padding-right: 80px;
  }

  .quoteHeading {
    left: 0%;

  }

  .quoteForm {
    /* margin-left: auto; */
    width: 350px;

  }

  #quoteLogo {
    text-align: center;
    top: 24%;
    left: 25%;
  }

  .sslImage {
    left: 20%;
  }


  #formInputMessage {
    height: 250px;
    width: 305px;
  }

  .hoursOfOperation {
    margin-left: -10px;
  }

  .formInstagramPost {
    left: 15%;
    margin-left: -25%;

  }


  .quoteImg {
    left: 15%;
  }

  .location-container {
    grid-template-columns: 100%;
  }

  .simpleMap {
    width: 100%;
  }

  /* Footer */
  .footer-grid-container {
    display: grid;
    grid-template-columns: 100%;
  }


  .footer-social-icon {}

  .social-text {
    font-size: 0.7rem;
  }

  /* ABOUT PAGE */
  /* .about-grid-container {
    grid-template-columns: 100%;
    grid-template-rows: 1200px;
  } */

  .about-grid-item {
    width: 100%;

  }

  .vanPic {
    width: 260px;
    height: 250px;
  }

  /* ABOUT PAGE */

  /* .contact-grid-container {
    grid-template-columns: 100%;
    margin-top: 250px;
  } */

  /* .contact-grid-item {} */

  .contactSupportImage {
    width: 270px;
    height: 270px;
    position: relative;
    /* top: -0vh; */
    margin-left: -3rem;
  }

  .buttonEmailUs {
    margin-top: 120px;
  }

  .buttonEmailUsText {
    margin-left: 10px;
  }

  .buttonCallUs {
    margin-top: -70px;
    margin-left: 0px;
    bottom: 15px;
  }

  .buttonCallUsText {
    font-size: 1.2rem;
  }

  .contactPara {
    margin-right: 30px;
  }

  .contactParaResponsive {
    display: none;
  }

  /* .contactCard {
    margin-left: 0;
  } */

}



/* MEDIA QUERIES */