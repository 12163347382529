body {
    /* background: #222222; */
    /* margin: 50px 0 0; */
    overflow-x: hidden;

}

.text-container {
    width: 900px;
    margin: auto;
    overflow-x: hidden;
    height: 500px;
    margin-top: 65vh;
    border: 20px solid red;
    /* border: 20px solid red; */


}

.target {
    font-weight: bold;
    /* color: white; */
    animation-name: rightToLeft;
    animation-duration: 9.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    font-size: 1.8rem;
    background-image:
        repeating-radial-gradient(circle, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.75));
    text-align: center;
    /* border: 20px solid red; */

    /* animation: rightToLeft 4.5s linear infinite; */
}

@keyframes rightToLeft {
    0% {
        transform: translateX(600px);
    }

    100% {
        transform: translateX(-760px);
    }
}


@media only screen and (max-width: 768px) {
    .text-container {
        margin-top: 15vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1450px) {
    .text-container {
        margin-top: 15vh;
    }
}