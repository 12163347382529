/*
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #000;
}*/

.glow-effect {
    /* width: 220px;
    height: 50px; */
    /* border: none; */
    outline: none;
    color: #fff;
    background: #fffefe;
    /* changed */
    background-color: rgba(0, 0, 0, 0);
    /* changed */
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin-top: -5px;


}

.glow-effect:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    /* changed to this 1 so it is always glowing (not on hover only) */
    transition: opacity .3s ease-in-out;
    border-radius: 10px;


}

.glow-effect:active {
    color: #000
}

.glow-effect:active:after {
    background: transparent;
}

.glow-effect:hover:before {
    opacity: 1;
}

.glow-effect:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #9146FF;
    /* changed */
    background-color: rgba(0, 0, 0, 0);
    /* changed */
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

/* range of low-resolution laptop */
@media only screen and (min-width: 1200px) and (max-width: 1380px) {
    .glow-effect:before {
        top: -2px;
        left: -2px;

    }

    .glow-effect:after {
        left: 0;
        top: 0;

    }
}